<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="text-wrap"
      >
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="text-wrap"
      >
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <b10-list-item-right-avatar
      :color="formattedItem.badgeColor"
    >
      {{ formattedItem.badge }}
    </b10-list-item-right-avatar>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { TIPOS_MOVIMIENTO_CAJA } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.concepto
      item.subtitle = this.$options.filters.shortDate(item.fmovimiento)
      item.avatar = this.$options.filters.initials(item.tmovcaja_desc)
      // item.avatar = parseInt(item.idtmovcaja) === TIPOS_MOVIMIENTO_CAJA.tipos.ingresoManual ? '+' : '-'
      item.avatarColor = TIPOS_MOVIMIENTO_CAJA.colores[item.idtmovcaja]
      item.badge = this.$options.filters.currency(item.importe)
      return item
    }
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    },
  },
}
</script>
