import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectMovimientoCaja (Vue, filter, search, sorter, page, idempleado) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idempleado', idempleado)
      .addExact('parausuario', 1)
      .addGT('estado', 0)

    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('concepto', search)
        .addILike('importe', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    const resp = await Vue.$api.call(
      'movcaja.selectList', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectMovimientoCajaRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idmovcaja', pks)
    const resp = await Vue.$api.call('movcaja.selectList', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
